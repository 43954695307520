import Lottie from "react-lottie";

export const NotifyMessage = () => {
  return (
    <div className="bg-white rounded-2xl p-8 py-12 shadow-lg shadow-gray-500/0 custom-lg:shadow-gray-500/10 font-custom">
      <div className="w-5/5 mx-auto">
        <div className="flex items-center justify-center">
          <Lottie
            options={{
              loop: false,
              autoplay: true,
              animationData: require("../../animation/sparq-success.json"),
            }}
            height={100}
            width={100}
          />
        </div>
        <div className="space-y-4 max-w-[250px]">
          <h1 className="text-center text-2xl font-medium mt-6">
            You are all set!
          </h1>
          <h2 className="text-center mt-1 text-sm font-medium text-neutral-400">
            We will notify you once we support your vehicle or device.
          </h2>
        </div>
      </div>
      <div className="mt-12 flex items-center justify-center">
        <span className="block font-medium  text-neutral-400">
          Stay up to date with @joinsparq
        </span>
      </div>
      <div className="mt-6 flex items-center justify-center">
        <img src="/images/instagram.svg" alt="sparq" className="cursor-pointer	" onClick={()=>{
          window.open("https://www.instagram.com/joinsparq/", "_blank")
        }}/>
        <img src="/images/linkedin.svg" alt="sparq" className="pl-[33px] cursor-pointer	" onClick={()=>{
          window.open("https://www.linkedin.com/company/sparqinc", "_blank")
        }}/>
        <img src="/images/x.svg" alt="sparq" className="pl-[33px] cursor-pointer	" onClick={()=>{
          window.open("https://x.com/joinsparq", "_blank")
        }}/>
      </div>
    </div>
  );
};
