import Lottie from "react-lottie";

export const RefundMessage = () => {

  return (
    <div className="bg-white rounded-2xl p-8 py-12 shadow-lg shadow-gray-500/0 custom-lg:shadow-gray-500/10 font-custom">
      <div className="w-5/5 mx-auto">
        <div className="flex items-center justify-center">
          <Lottie
            options={{
              loop: false,
              autoplay: true,
              animationData: require("../../animation/sparq-success.json"),
            }}
            height={100}
            width={100}
          />
        </div>
        <div className="space-y-4 max-w-[450px]">
          <h1 className="text-center text-2xl font-medium mt-6">
          Payment has been refunded
          </h1>
          <h2 className="text-center mt-1 text-sm font-medium text-neutral-400">
          The refund may take between 5-10 business days to be reflected in your account, depending on your bank or credit card provider. Please contact support@joinsparq.com if you have any questions.
          </h2>
        </div>
      </div>
      <div className="mt-12 flex items-center justify-center">
        <button
          type="button"
          className="border rounded-full py-3 px-6 active:opacity-50"
        >
          <span className="block font-medium" onClick={
            () => window.location.href = "https://www.joinsparq.com"
          }>Back to Homepage</span>
        </button>
      </div>
    </div>
  );
};
