export function twMerge(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function getSubdomainIndex() {
  const domainParts = window.location.hostname.split(".");
  if (domainParts.length === 2) {
    return 1; // example.com
  } else if (domainParts.length === 3) {
    return 2; // www.example.com
  }
  return 0; // com
}

export function getFBPAndFBC() {
  const cookies = document.cookie.split(";");
  let fbp = null,
    fbc = null;

  // Get fbp and fbc from cookies
  cookies.forEach((cookie) => {
    const [name, value] = cookie.trim().split("=");
    if (name === "_fbp") {
      fbp = value;
    }
    if (name === "_fbc") {
      fbc = value;
    }
  });

  // Construct fbc from URL parameters if not found in cookies
  if (!fbc) {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("fbclid")) {
      const subdomainIndex = getSubdomainIndex();
      const creationTime = Date.now(); // Unix time in milliseconds
      const fbclid = urlParams.get("fbclid");
      fbc = `fb.${subdomainIndex}.${creationTime}.${fbclid}`;
    }
  }

  return { fbp, fbc };
}

export async function fetchCloudflareTraceData() {
  try {
    const response = await fetch('https://www.cloudflare.com/cdn-cgi/trace');
    const text = await response.text();
    
    const lines = text.split('\n');
    const data = {};
    
    lines.forEach(line => {
      const [key, value] = line.split('=');
      if (key && value) {
        data[key.trim()] = value.trim();
      }
    });
    
    const { ip, uag, loc } = data;
    
    return { ip, uag, loc };
  } catch (error) {
    console.error('Error fetching Cloudflare trace data:', error);
    return null;
  }
}