import { twMerge } from "../../utils";
import React, { useState } from 'react';

export const ProductCard = ({ quantity, onQuantityChange }) => {
  // const [quantity, setQuantity] = useState(2);
  const unitPrice = 499.00;
  const shippingPrice = 20.00;

  const increaseQuantity = () => {
    onQuantityChange(quantity + 1);
  };

  const decreaseQuantity = () => {
    if (quantity > 1) {
      onQuantityChange(quantity - 1);
    }
  };

  return (
    <div
      className={twMerge(
        "flex justify-center custom-lg:justify-end",
        "custom-lg:absolute custom-lg:top-7 bottom-0 left-0 custom-lg:w-1/2",
        "custom-lg:bg-custom-bg custom-lg:pr-14 custom-lg:pl-4 h-fit",
        "bg-white -mb-40 custom-lg:mb-0 relative z-10"
      )}
    >
      <div
        className={twMerge(
          "w-full bg-white rounded-2xl shadow-lg shadow-gray-500/0 custom-lg:shadow-gray-500/5",
          "p-6 pt-8 border-0 custom-lg:border max-w-[412px] custom-lg:max-w-[620px]"
        )}
      >
        <Header />
        <div className="bg-custom-bg custom-lg:bg-white p-4 custom-lg:p-0 rounded-2xl mt-5">
          <Illustration />
          <QuantityBox 
            quantity={quantity} 
            increaseQuantity={increaseQuantity} 
            decreaseQuantity={decreaseQuantity}
          />
          <PriceCalculationTotal 
            quantity={quantity}
            unitPrice={unitPrice}
            shippingPrice={shippingPrice}
          />
        </div>
      </div>
    </div>
  );
};

const Header = () => {
  return (
    <div className="flex items-center justify-between">
      <img src="/assets/logo.png" className="w-[95px]" />
    </div>
  );
};

const Illustration = () => {
  return (
    <div className="custom-lg:my-6 mb-6">
      <div className="w-full rounded-xl overflow-hidden relative min-h-[230px] custom-lg:min-h-[225px]">
        <img
          src="/assets/background.png"
          className="absolute top-0 left-0 bottom-0 right-0 custom-xlg:relative object-cover w-full h-full"
        />
        <div className="absolute bottom-6 left-4 space-y-2 custom-xlg:space-y-4">
          <div className="block">
            <span className="block font-medium text-xl custom-lg:text-3xl custom-xlg:text-4xl text-white">
              Reimagine the
            </span>
            <span className="block font-medium text-xl custom-lg:text-3xl custom-xlg:text-4xl text-white">
              relationship
            </span>
            <span className="block font-medium text-xl custom-lg:text-3xl custom-xlg:text-4xl text-brand">
              with your car
            </span>
          </div>
          <div className="space-y-3">
            <span className="block font-medium text-[12px] text-neutral-400">
              Currently supporting
            </span>
            <div className="flex items-start justify-start flex-wrap">
              <div className="flex shrink-0 items-center px-2 custom-lg:px-4 py-1 h-8 rounded-full bg-neutral-600 mr-2">
                <div className="flex items-center space-x-1">
                  <img
                    src="/assets/apple_icon.svg"
                    className="w-[15px] -mt-0.5 -ml-1"
                  />

                  <span className="block font-bold text-[12px] text-neutral-100">
                    IOS
                  </span>
                </div>
              </div>
              <div className="flex shrink-0 items-center px-2 custom-lg:px-4 py-1 h-8 rounded-full bg-neutral-600">
                <div className="flex items-center">
                  <span className="block font-bold text-[12px] text-neutral-100">
                    Vehicles 2014 and Newer
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const QuantityBox = ({ quantity, increaseQuantity, decreaseQuantity }) => {
  return (
    <div className="flex items-center justify-between bg-white custom-lg:bg-custom-bg p-6 pr-4 rounded-xl flex-wrap">
      <div className="flex items-center justify-center space-x-4">
        <img src="/assets/sparq-device.png" className="w-[70px] custom-lg:w-[90px]" />
        <div className="block">
          <span className="block font-bold text-sm">SPARQ Diagnostics</span>
          <span className="block text-neutral-400 text-sm">
            Pre-Order. Limited Time Offer
          </span>
        </div>
      </div>
      <div className="flex items-center w-full custom-lg:w-fit justify-between custom-lg:justify-center space-x-2 pt-4 custom-xlg:pt-0">
        <div className="flex items-center justify-start w-40 custom-lg:w-28 h-8 bg-neutral-200/50 rounded-md">
          <button
            type="button"
            className="flex-1 text-neutral-500 font-sans active:opacity-50"
            onClick={decreaseQuantity}
          >
            <span className="text-lg -ml-2">-</span>
          </button>
          <div className="flex justify-center items-center min-w-6">
            <span className="custom-lg:hidden text-sm font-medium mr-2 block">
              Quantity
            </span>
            <span className="block text-sm font-medium">{quantity}</span>
          </div>
          <button
            type="button"
            className="flex-1 text-neutral-500 active:opacity-50"
            onClick={increaseQuantity}
          >
            <span className="text-lg -mr-2">+</span>
          </button>
        </div>
        <div className="flex items-center space-x-2">
          <span className="text-neutral-400 text-sm font-medium line-through">
            $499.00
          </span>
          <span className="text-brand text-sm font-bold">Free</span>
        </div>
      </div>
    </div>
  );
};

const PriceCalculationTotal = ({ quantity, unitPrice, shippingPrice }) => {
  const totalShipping = quantity * shippingPrice;

  return (
    <div className="flex justify-end mt-4 w-full bg-white p-4 py-4 custom-lg:p-0 rounded-xl">
      <div className="flex flex-col items-end space-y-4 w-full">
        <div className="flex items-center space-x-1 custom-lg:space-x-8 w-full custom-lg:w-fit justify-end">
          <span className="block text-[13px] custom-lg:text-sm whitespace-nowrap">
            SPARQ Diagnostics
            <span className="hidden custom-lg:inline-block ml-1">
              {" "}
              - Promo Code Applied
            </span>
            <span className="inline-block custom-lg:hidden ml-1">w/ promo</span>
          </span>
          <span className="block text-[13px] custom-lg:text-sm text-neutral-400 font-medium line-through w-[78px] text-end">
            {quantity} x ${unitPrice.toFixed(2)}
          </span>
        </div>
        <div className="flex items-center space-x-1 custom-lg:space-x-8 w-full custom-lg:w-fit justify-end">
          <span className="block text-[13px] custom-lg:text-sm whitespace-nowrap">Shipping, Handling & Taxes</span>
          <span className="block text-[13px] custom-lg:text-sm text-neutral-400 font-medium w-[78px] text-end">
            {quantity} x ${shippingPrice.toFixed(2)}
          </span>
        </div>
        <div className="flex items-center space-x-1 custom-lg:space-x-8 w-full custom-lg:w-fit justify-end">
          <span className="block text-[13px] custom-lg:text-sm  custom-lg:font-bold whitespace-nowrap">Total</span>
          <span className="block text-[13px] custom-lg:text-sm text-brand font-bold w-[78px] text-end">
            ${totalShipping.toFixed(2)}
          </span>
        </div>
      </div>
    </div>
  );
};
