import Lottie from "react-lottie";

export const SuccessMessage = ({sessionId}) => {
  const orderID = sessionId.slice(-10);

  const handleCopyToClipboard = async (value) => {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(value);
    } else {
      return document.execCommand("copy", true, value);
    }
  };

  return (
    <div className="bg-white rounded-2xl p-8 py-12 shadow-lg shadow-gray-500/0 custom-lg:shadow-gray-500/10 font-custom">
      <div className="w-4/5 mx-auto">
        <div className="flex items-center justify-center">
          <Lottie
            options={{
              loop: false,
              autoplay: true,
              animationData: require("../../animation/sparq-success.json"),
            }}
            height={100}
            width={100}
          />
        </div>
        <div className="space-y-4 max-w-[350px]">
          <h1 className="text-center text-2xl font-medium mt-6">
            Congratulations
          </h1>
          <h2 className="text-center mt-1 text-sm font-medium text-neutral-400">
            Your order has been placed and will be shipped within 3-5 business days from vehicle confirmation.
          </h2>
          <div
            onClick={() => handleCopyToClipboard(orderID)}
            className="w-fit px-4 pr-3 py-2 bg-neutral-100 rounded-md mx-auto"
          >
            <div className="flex items-center justify-space-between space-x-3">
              <span className="block text-sm">Order ID: {orderID}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-12 flex items-center justify-center">
        <button
          type="button"
          className="border rounded-full py-3 px-6 active:opacity-50"
        >
          <span className="block font-medium" onClick={
            () => window.location.href = "https://www.joinsparq.com"
          }>Back to Homepage</span>
        </button>
      </div>
    </div>
  );
};
