// src/hooks/useFacebookPixel.ts
import { useEffect, useState } from 'react';
import { FacebookPixel } from 'react-use-facebook-pixel';

const useFacebookPixel = () => {
  const [facebookPixel, setFacebookPixel] = useState(null);

  useEffect(() => {
    const initializeFacebookPixel = async () => {
      const pixel = new FacebookPixel({
        pixelID: '1400124148058230',
      });

      pixel.init({});

      setFacebookPixel(pixel);
    };

    initializeFacebookPixel();
  }, []);

  return facebookPixel;
};

export default useFacebookPixel;